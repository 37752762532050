import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import Image from 'next/image';
export const teamToProps = ({ name = 'logo', light_image = '', dark_image = '' }) => {
    return { alt: name, lightSrc: light_image, darkSrc: dark_image };
};
export const logoLeagueToProps = ({ team = 'logo', light_logo = '', dark_logo = '', }) => {
    return { alt: team, lightSrc: light_logo, darkSrc: dark_logo };
};
// TODO: Automatically add data-attribute and name based on component name
export const LogoWithDisc = ({ alt, lightSrc, darkSrc, altBg = true, className }) => {
    return (_jsxs("div", { "data-component-name": "LogoWithDisc", className: clsx(className, 'w-fit p-[15px] size-auto rounded-full', { 'bg-bg-alt': altBg }, { 'bg-bg': !altBg }), children: [_jsx(Image, { alt: alt, src: lightSrc, width: "26", height: "26", className: "block dark:hidden" }), _jsx(Image, { alt: alt, src: darkSrc, width: "26", height: "26", className: "dark:block hidden" })] }));
};
