import { useColorScheme } from '@/hooks/useColorScheme'
import { getString } from '@grandstand-web/bally-web-shared/src/newPackages/StringMgmt'
import { LogoWithDisc, teamToProps } from '@grandstand-web/ui'
import { PartnerTeam } from '@grandstand/presentation-models'
import * as Styles from './styles'

export const UpsellHeader = ({
  availableTeams,
  outOfMarket,
}: {
  availableTeams: PartnerTeam[]
  outOfMarket: boolean
}) => {
  const { isDark } = useColorScheme()
  if (outOfMarket) {
    return (
      <>
        <Styles.ContentRow>
          <Styles.ContentTitle fontSize="3">{"We're sorry"}</Styles.ContentTitle>
          <Styles.ContentSubtitle fontSize="1">
            FanDuel Sports Network is not available in this zip code
          </Styles.ContentSubtitle>
        </Styles.ContentRow>
        <Styles.ContentRow>
          <Styles.TeamsOutOfMarket>No packages available</Styles.TeamsOutOfMarket>
        </Styles.ContentRow>
      </>
    )
  }
  return (
    <>
      <Styles.ContentRow>
        <Styles.ContentTitle fontSize="3">{getString('upsell.purchase.header.right')}</Styles.ContentTitle>
        <Styles.ContentSubtitle fontSize="1">{getString('upsell.purchase.subheader.right')}</Styles.ContentSubtitle>
      </Styles.ContentRow>
      <Styles.ContentRow>
        <Styles.TeamsInMarket>
          {availableTeams.map((team) => {
            const logo = team.light_image || ''
            return (
              <Styles.TeamInfo className="light" key={team.name}>
                <LogoWithDisc altBg={false} {...teamToProps(team)} />
                <Styles.TeamDetails>
                  <Styles.TeamName>{team.name}</Styles.TeamName>
                  <Styles.TeamMeta>
                    {team.league?.toUpperCase()} {team.dtc ? '' : '• TV Provider Only'}
                  </Styles.TeamMeta>
                </Styles.TeamDetails>
              </Styles.TeamInfo>
            )
          })}
        </Styles.TeamsInMarket>
      </Styles.ContentRow>
    </>
  )
}
